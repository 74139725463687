import React from 'react'
import {Link} from 'gatsby'
import img8 from '../../assets/images/about/about-img8.png'
import shape1 from '../../assets/images/about/about-shape1.png'

const AboutUs = () => {
    return (
        <section className="about-area res-pt-0 pb-100 pt-70">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <div className="about-img">
                            <img src={img8} alt="image" />
                            <div className="shape">
                                <img src={shape1} alt="image" />
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="about-content">
                            <div className="content">
                                <h2>Seguridad en tu mano</h2>
                                <p>Accede de forma más segura y confiable a tus datos asignados</p>

                                <ul className="content-list">
                                    <li><i className="flaticon-tick"></i>Registro por numero telefonico</li>
                                    <li><i className="flaticon-tick"></i>Registro por credenciales</li>
                                    <li><i className="flaticon-tick"></i>Geolocalización</li>
                                </ul>

                                <p>Te brindamos doble autenticación para poder acceder a la aplicación Seq GPS, de esa forma la información es protegida por inconvenientes por terceros o si el móvil es extraviado.

                                </p>
                               
                               {/*  <Link to="/nosotros" className="default-btn">
                                    <i className="flaticon-right"></i> 
                                    Más acerca de nosotros <span></span>
                                </Link> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default AboutUs