import React from 'react'
import {Link} from 'gatsby'
import service3 from '../../assets/images/services/service333.png'

import starIcon from '../../assets/images/star-icon.png'
import shape1 from '../../assets/images/shape/circle-shape1.png'
import shape2 from '../../assets/images/about/about-shape1.png'

const AboutArea = () => {
    return (
        <section className="about-area pb-100">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
    
                        <div className="about-img">
                            <img src={service3} alt="image" />
                            <div className="shape">
                                <img src={shape2} alt="image" />
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="about-content">
                            <div className="content">
                                <span className="sub-title">
                                  {/*   <img src={starIcon} alt="about" />  */}
                                    Administración
                                </span>
                                <h2>Portal administrativos</h2>
                                <p>Asigna, gestión y administra todo tu proceso desde un contra mando para optimizar tus visitas</p>

                                <ul className="about-list">
                                    <li>
                                        <i className="flaticon-tick"></i>
                                        Reporte de kilometraje
                                      {/*   <img src={shape2} alt="about" /> */}
                                    </li>
                                    <li>
                                        <i className="flaticon-tick"></i>
                                       Seguimiento
                                      {/*   <img src={shape2} alt="about" /> */}
                                    </li>
                                    <li>
                                        <i className="flaticon-tick"></i>
                                        Reporte de visitas
                                      {/*   <img src={shape2} alt="about" /> */}
                                    </li>
                                    <li>
                                        <i className="flaticon-tick"></i>
                                  Creación de
                                      {/*   <img src={shape2} alt="about" /> */}
                                    </li>
                                </ul>
                                <p>Si quieres saber sobre nuestro inicio en la industria tecnología te invitamos acceder en el siguiente botón</p>
                                <Link to="/nosotros" className="default-btn">
                                    <i className="flaticon-right"></i> 
                                    Saber más de nosotros
                                    <span></span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="circle-shape1">
                <img src={shape1} alt="about" />
            </div>
        </section>
    )
}

export default AboutArea;