import React from 'react'
import {Link} from 'gatsby'
import mission2 from '../../assets/images/our-mission/laptop-seqGPS2.png'
import shape1 from '../../assets/images/our-mission/our-mission-shape1.png'

const OurMission = () => {
    return (
        <div className="our-mission-area ptb-100">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <div className="our-mission-content">
                            <div className="content">

                            <span className="sub-title">
                {/*         <img src={starIcon} alt="features" />  */}
                       GPS
                    </span>
                                <h2>Control de monitoreo de vistas de cobro en calle</h2>
                                <p>Administras de forma rápida el seguimiento diario del trayecto de tus rutas.</p>
                                <ul className="features-list">
                                    <li><i className="flaticon-tick"></i> Ubicación de agentes de cobros en tiempo real e histórico</li>
                                    <li><i className="flaticon-tick"></i> Integración de sistema de cobranza</li>
                                    <li><i className="flaticon-tick"></i> Reportería de visitas realizadas</li>
 

                                </ul>
                               {/*  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
 */}
                     {/*            <Link to="/membership-levels" className="default-btn">
                                    <i className="flaticon-right"></i> 
                                    Contáctanos <span></span>
                                </Link> */}
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="our-mission-image">
                            <img src={mission2} alt="shape" />
                            <div className="shape">
                                <img src={shape1} alt="shape" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OurMission