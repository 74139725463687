import React from 'react';


import icon1 from '../../assets/images/icons/icon1.png'
import icon2 from '../../assets/images/icons/icon2.png'
import icon8 from '../../assets/images/icons/icon8.png'
import icon9 from '../../assets/images/icons/icon9.png'
import icon10 from '../../assets/images/icons/icon10.png'
import icon11 from '../../assets/images/icons/icon11.png'
import shape7 from '../../assets/images/shape/vector-shape7.png'
import shape8 from '../../assets/images/shape/vector-shape8.png'


const Services = () => {
    return (

        
        <div className="boxes-area pb-70">
            <div className="container">
            <div className="section-title">

            <span className="sub-title">
                      {/*   <img src={StarIcon} alt="StarIcon" />  */}
                      BENEFICIOS
                    </span> 

                    <h2>Lo que te brinda nuestra Aplicación móvil</h2>
{/*                     <p>Las siguientes ramas de la industria de desarrollo web</p> */}
                </div>

                <div className="row">
            



                    <div className="col-lg-3 col-md-6 col-sm-6">
                        <div className="single-services-box">
                            <div className="icon">

                                <img src={icon1} alt="banner" />

                            </div>
                            <h3>
                            Mayor participación en el mercado 
                            </h3>
                           {/*  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
                             */}
                            {/* <Link to="/service-details" className="read-more-btn">
                                Read More <i className="flaticon-right"></i>
                            </Link> */}
                            
                        </div>
                    </div>


                    
                    <div className="col-lg-3 col-md-6 col-sm-6">
                        <div className="single-services-box">
                            <div className="icon">
                                <img src={icon2} alt="banner" />
                            </div>
                            <h3>

                            Mejor rendimiento de la asistencia, los agentes y el equipo
 
                            </h3>
                           {/*  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
                             */}
                            {/* <Link to="/service-details" className="read-more-btn">
                                Read More <i className="flaticon-right"></i>
                            </Link> */}
                        </div>
                    </div>



                    <div className="col-lg-3 col-md-6 col-sm-6">
                        <div className="single-services-box">
                            <div className="icon">
                                <img src={icon11} alt="banner" />
                            </div>
                            <h3>

                            Recopilación de manera más eficiente y óptima

                            </h3>
                           {/*  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
                             */}
                            {/* <Link to="/service-details" className="read-more-btn">
                                Read More <i className="flaticon-right"></i>
                            </Link> */}
                        </div>
                    </div>


                    
                    <div className="col-lg-3 col-md-6 col-sm-6">
                        <div className="single-services-box">
                            <div className="icon">
                                <img src={icon9} alt="banner" />
                            </div>
                            <h3>
              
                            Informes, seguimiento y análisis de datos
                    
                            </h3>
                           {/*  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
                             */}
                            {/* <Link to="/service-details" className="read-more-btn">
                                Read More <i className="flaticon-right"></i>
                            </Link> */}
                        </div>
                    </div>


                </div>




            </div>


            <div className="vector-shape7">
                <img src={shape7} alt="features" />
            </div>
    
            <div className="vector-shape8">
                <img src={shape8} alt="features" />
            </div>
        </div>
    )
}
export default Services;